<script setup lang="ts">
import type { FormError } from "#ui/types";
const { t } = useI18n();
const fields = reactive([
  {
    name: "email",
    type: "text",
    label: computed(() => t("userForms.email")),
    placeholder: computed(() => t("userForms.emailPlaceholder")),
  },
]);

const validate = (state: any) => {
  const errors: FormError[] = [];
  if (!state.email) errors.push({ path: "email", message: "Email is required" });
  return errors;
};

function onSubmit(data: any) {
  console.log("Submitted", data);
}

const buttonUI = reactive({
  base: "text-center",
  footer: "text-center",
  default: {
    submitButton: {
      label: computed(() => t("userForms.sendEmail")),
    },
  },
});
</script>

<template>
  <UAuthForm
    class="max-w-full px-6"
    :fields="fields"
    :validate="validate"
    :title="$t('userForms.resetPassword')"
    align="top"
    icon="i-heroicons-lock-closed"
    :ui="buttonUI"
    @submit="onSubmit"
  >
    <template #description>
      {{ $t("userForms.backTo") }}
      <span @click="$emit('login')" class="text-primary font-medium cursor-pointer"
        >{{ $t("userForms.login") }}
      </span>
    </template>
  </UAuthForm>
</template>
