<template>
  <UDashboardPanel v-model="selected" side="left" :width="250" collapsible
    class="h-[100vh] z-[49] sticky top-0 left-0 shadow-[rgba(0,0,0,0.1)_15px_0px_8px_0px]">
    <UDashboardNavbar class="mt-5 pb-4" :ui="{ left: 'justify-between lg:w-fit w-full' }">
      <template #center>
        <nuxt-link to="/" class="flex mx-auto">
          <NuxtImg format="webp" class="object-contain h-14" src="/logo.png" alt="Logo Icon" />
          <NuxtImg format="webp" class="object-contain h-14" src="/logo-text.png" alt="Logo Text" />
        </nuxt-link>
      </template>
      <template #toggle class="w-full justify-between flex">
        <nuxt-link to="/" class="flex lg:hidden">
          <NuxtImg format="webp" class="object-contain h-14" src="/logo.png" alt="Logo Icon" />
          <NuxtImg format="webp" class="object-contain h-14" src="/logo-text.png" alt="Logo Text" />
        </nuxt-link>
        <UDashboardNavbarToggle></UDashboardNavbarToggle>
      </template>
    </UDashboardNavbar>

    <UDashboardSidebar>
      <ModalLogin :config="linksUI"></ModalLogin>
      <UDivider> </UDivider>
      <UAsideLinks :links="links" :ui="linksUI" />
      <UDivider> </UDivider>
      <UAsideLinks :links="submitLinks" :ui="linksUI" />
      <UDivider> </UDivider>
      <UAsideLinks :links="[
        {
          label: $t('advertise'),
          icon: 'i-ph-handshake-bold',
          to: '/advertise',
          click: () => {
            bus.emit(false);
          },
        },
      ]" :ui="linksUI" />
    </UDashboardSidebar>

    <UFooter :ui="footerUI" :links="footerLinks">
      <template #right> CryptoProjectsIO © {{ new Date().getFullYear() }} </template>

      <template #center>
        <UFooterLinks :links="footerLinks" :ui="{
          wrapper: 'flex-row',
        }"></UFooterLinks>
      </template>

      <template #left>
        <UButton aria-label="X" icon="i-ph-twitter-logo-fill" color="gray" variant="ghost"
          to="https://x.com/CryptoProjectIO" target="_blank" />
        <UButton aria-label="Discord" icon="i-ph-discord-logo-fill" color="gray" variant="ghost"
          to="https://discord.gg/dEE93gRy2y" target="_blank" />
        <UButton aria-label="Telegram" icon="i-ph-telegram-logo-bold" color="gray" variant="ghost"
          to="https://t.me/CryptoProjectsIO" target="_blank" />
      </template>
    </UFooter>
  </UDashboardPanel>
</template>

<script setup lang="ts">
import { useEventBus } from "@vueuse/core";
const { t } = useI18n({ useScope: "global" });

let submitLinks = reactive([
  {
    label: computed(() => t("submitCoin")),
    icon: "i-ph-note-pencil-fill",
    to: "/submit/coin",
    click: () => {
      bus.emit(false);
    },
  },
]);
const links = reactive([
  {
    label: computed(() => t("homepage")),
    icon: "i-heroicons-book-open-solid",
    to: "/",
    click: () => {
      bus.emit(false);
    },
  },
  {
    label: computed(() => t("news")),
    icon: "i-ph-newspaper-fill",
    to: "/news",
    click: () => {
      bus.emit(false);
    },
  },
  {
    label: computed(() => t("coins")),
    icon: "i-ph-coins-fill",
    to: "/coins",
    click: () => {
      bus.emit(false);
    },
  },
]);
const linksUI = linksUICMP;
const footerLinks = reactive([
  {
    label: computed(() => t("policy")),
    to: "/privacy-policy",
    click: () => {
      bus.emit(false);
    },
  },
  {
    label: computed(() => t("terms")),
    to: "/terms-of-use",
    click: () => {
      bus.emit(false);
    },
  },
  {
    label: computed(() => t("contact")),
    to: "https://t.me/BlaineCryptoProjectsIO",
    target: "_blank",
    click: () => {
      bus.emit(false);
    },
  },
]);
const footerUI = footerUICMP;

const bus = useEventBus<boolean>("navOpened");

const selected = ref(false);

bus.on((value) => {
  if (selected.value !== value) {
    selected.value = value;
  }
});

watch(selected, () => {
  bus.emit(selected.value);
});
</script>
