<script setup>
const openNav = ref(false);
</script>

<template>
  <SideNavigation :opened="openNav" />
  <div class="w-full overflow-x-hidden">
    <ClientOnly>
      <Navigation></Navigation>
    </ClientOnly>
    <slot />
  </div>
</template>
