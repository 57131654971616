<script setup lang="ts">
import type { FormError } from "#ui/types";
const { t } = useI18n();

const fields = reactive([
  {
    name: "email",
    type: "text",
    label: computed(() => t("userForms.email")),
    placeholder: computed(() => t("userForms.emailPlaceholder")),
  },
  {
    name: "password",
    label: computed(() => t("userForms.password")),
    type: "password",
    placeholder: computed(() => t("userForms.passwordPlaceholder")),
  },
]);

const validate = (state: any) => {
  const errors: FormError[] = [];
  if (!state.email) errors.push({ path: "email", message: "Email is required" });
  if (!state.password) errors.push({ path: "password", message: "Password is required" });
  return errors;
};

const user = useSupabaseUser();
const email = ref("");
const password = ref("");
const errorMsg = ref("");
const loading = ref(false);

const userLogin = async () => {
  try {
    loading.value = true;
    const { error } = await useSupabaseClient().auth.signInWithPassword({
      email: email.value,
      password: password.value,
    });
    email.value = "";
    password.value = "";
    if (error) throw error;
  } catch (error: any) {
    loading.value = false;
    errorMsg.value = error.message;
    setTimeout(() => {
      errorMsg.value = "";
    }, 3000);
  }
};

watchEffect(() => {
  if (user.value) {
    reloadNuxtApp();
  }
});

const config = {
  default: {
    submitButton: {
      label: "Login",
    },
  },
};

const buttonUI = reactive({
  base: "text-center",
  footer: "text-center",
  default: {
    submitButton: {
      label: computed(() => t("userForms.login")),
    },
  },
});

function onSubmit(data: any) {
  email.value = data.email;
  password.value = data.password;
  userLogin();
}
</script>

<template>
  <UAuthForm
    class="max-w-full px-6"
    :fields="fields"
    :validate="validate"
    :title="$t('userForms.welcome')"
    align="top"
    icon="i-heroicons-lock-closed"
    :loading="loading"
    :ui="buttonUI"
    @submit="onSubmit"
  >
    <template #description>
      {{ $t("userForms.noAccount") }}
      <span @click="$emit('sign-up')" class="text-primary font-medium cursor-pointer"
        >{{ $t("userForms.signUp") }}
      </span>
    </template>

    <template #password-hint>
      <span
        @click="$emit('forgot-password')"
        class="text-primary font-medium cursor-pointer"
        >{{ $t("userForms.forgotPassword") }}</span
      >
    </template>
    <template #footer>
      {{ $t("userForms.bySigningIn") }}
      <NuxtLink to="/" class="text-primary font-medium">{{
        $t("userForms.terms")
      }}</NuxtLink
      >.
    </template>
  </UAuthForm>
</template>
