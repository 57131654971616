<script setup>
import { useEventBus } from "@vueuse/core";
const { toggleContentSearch } = useUIState();
const bus = useEventBus("navOpened");
const localeStore = useLocaleStore();
const router = useRouter();
const locales = [
  {
    label: "EN",
    value: "en",
    icon: "i-flag-us-4x3",
  },
  {
    label: "RU",
    value: "ru",
    icon: "i-flag-ru-4x3",
  },
  {
    label: "ZH",
    value: "zh",
    icon: "i-flag-cn-4x3",
  },
];

const selectedLocale = ref(
  locales.find((locale) => locale.label === localeStore.locale.label) || locales[0]
);

useHead({
  htmlAttrs: {
    lang: selectedLocale.value.value,
  },
});

// Watch for changes in selectedLocale and update the store
watch(selectedLocale, (newLocale) => {
  localeStore.setLocale(newLocale);
  useHead({
    htmlAttrs: {
      lang: newLocale.value,
    },
  });
});

const isOpen = ref(false);

const commandPaletteRef = ref();

const ui = {
  wrapper: "flex flex-col flex-1 min-h-0 bg-gray-50 dark:bg-gray-800",
  input: {
    wrapper: "relative flex items-center mx-3 py-3",
    base:
      "w-full rounded border-2 border-primary-500 placeholder-gray-400 dark:placeholder-gray-500 focus:border-primary-500 focus:outline-none focus:ring-0 bg-white dark:bg-gray-900",
    padding: "px-4",
    height: "h-14",
    size: "text-lg",
    icon: {
      base: "pointer-events-none absolute left-3 text-primary-500 dark:text-primary-400",
      size: "h-6 w-6",
    },
  },
  group: {
    wrapper: "p-3 relative",
    label:
      "-mx-3 px-3 -mt-4 mb-2 py-1 text-sm font-bold text-primary-500 dark:text-primary-400 sticky top-0 bg-gray-50 dark:bg-gray-800 z-10",
    container: "space-y-1",
    command: {
      base:
        "flex justify-between select-none items-center rounded px-2 py-4 gap-2 relative font-medium text-sm group shadow",
      active: "bg-primary-500 dark:bg-primary-400 text-white",
      inactive: "bg-white dark:bg-gray-900",
      label: "flex flex-col min-w-0",
      suffix: "text-xs",
      icon: {
        base: "flex-shrink-0 w-6 h-6",
        active: "text-white",
        inactive: "text-gray-400 dark:text-gray-500",
      },
    },
  },
  emptyState: {
    wrapper: "flex flex-col items-center justify-center flex-1 py-9",
    label: "text-sm text-center text-gray-500 dark:text-gray-400",
    queryLabel: "text-lg text-center text-gray-900 dark:text-white",
    icon: "w-12 h-12 mx-auto text-gray-400 dark:text-gray-500 mb-4",
  },
};

function onSelect(option) {
  if (option.click) {
    option.click();
  } else if (option.to) {
    router.push(option.to);
    isOpen.value = false;
  } else if (option.href) {
    window.open(option.href, "_blank");
  }
}

const uiModal = {
  container: "flex min-h-full items-start justify-center text-center",
};

const imaggeLink =
  "https://mjuzcxbizlhyxhjpvfjj.supabase.co/storage/v1/object/public/coin_pfp/";
const groups = [
  {
    key: "users",
    label: (q) => q && `Projects matching “${q}”...`,
    search: async (q) => {
      if (!q) {
      } else {
        // @ts-ignore
        const users = await $fetch("/api/get-projects/search", {
          query: { search: q },
        });

        const usersFormatted = users.projects;
        return usersFormatted.map((user) => ({
          id: user.id,
          to: `/coins/${user.id}`,
          target: "_self",
          label: `$${user.symbol}`,
          suffix: user.name,
          avatar: { src: `${imaggeLink}${user.pfp_url}` },
        }));
      }
    },
  },
];

const commandsUI = {
  wrapper: "flex flex-col flex-1 min-h-0 divide-y divide-gray-100 dark:divide-gray-800",
  container:
    "relative flex-1 overflow-y-auto divide-y divide-gray-100 dark:divide-gray-800 scroll-py-2",
  input: {
    wrapper: "relative flex items-center",
    base:
      "w-full placeholder-gray-400 dark:placeholder-gray-500 bg-transparent border-0 text-gray-900 dark:text-white focus:ring-0 focus:outline-none",
    padding: "px-4",
    height: "h-12",
    size: "sm:text-sm",
    icon: {
      base: "pointer-events-none absolute start-4 text-gray-400 dark:text-gray-500",
      loading: "animate-spin",
      size: "h-5 w-5",
      padding: "ps-11",
    },
    closeButton: {
      base: "absolute end-4",
      padding: "pe-10",
    },
  },
  emptyState: {
    wrapper: "flex flex-col items-center justify-center flex-1 px-6 py-14 sm:px-14",
    label: "text-sm text-center text-gray-900 dark:text-white",
    queryLabel: "text-sm text-center text-gray-900 dark:text-white",
    icon: "w-6 h-6 mx-auto text-gray-400 dark:text-gray-500 mb-4",
  },
  group: {
    wrapper: "p-2",
    label: "px-2.5 my-2 text-xs font-semibold text-gray-900 dark:text-white",
    container: "text-sm text-gray-700 dark:text-gray-200",
    command: {
      base:
        "flex justify-between select-none items-center rounded-md px-2.5 py-1.5 gap-2 relative",
      active: "bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white",
      inactive: "",
      label: "flex text-xl font-semibold items-center gap-1.5 min-w-0",
      prefix: "text-gray-400 dark:text-gray-500",
      suffix: "text-gray-400 dark:text-gray-500",
      container: "flex items-center gap-1.5 min-w-0",
      icon: {
        base: "flex-shrink-0 w-12 h-12",
        active: "text-gray-900 dark:text-white",
        inactive: "text-gray-400 dark:text-gray-500",
      },
      selectedIcon: {
        base: "h-5 w-5 text-gray-900 dark:text-white flex-shrink-0",
      },
      avatar: {
        base: "flex-shrink-0",
        size: "lg",
      },
      chip: {
        base: "flex-shrink-0 w-2 h-2 mx-1 rounded-full",
      },
      disabled: "opacity-50",
      shortcuts: "hidden md:inline-flex flex-shrink-0 gap-0.5",
    },
    active: "flex-shrink-0 text-gray-500 dark:text-gray-400",
    inactive: "flex-shrink-0 text-gray-500 dark:text-gray-400",
  },
  default: {
    icon: "i-heroicons-magnifying-glass-20-solid",
    loadingIcon: "i-heroicons-arrow-path-20-solid",
    emptyState: {
      icon: "i-heroicons-magnifying-glass-20-solid",
      label: "We couldn't find any items.",
      queryLabel: "We couldn't find any items with that term. Please try again.",
    },
    closeButton: null,
    selectedIcon: "i-heroicons-check-20-solid",
  },
};
</script>

<template>
  <div class="w-full fixed top-0 left-0 right-0 z-[48] shadow-2xl">
    <div class="grid grid-cols-3 gap-8 w-full h-[75.6px] py-4 bg-gray-900 border-b border-gray-800">
      <div class="col-span-1 lg:col-span-1 flex items-center justify-center">
        <nuxt-link to="/" class="flex mx-auto ml-4 lg:hidden">
          <NuxtImg format="webp" class="object-contain h-14" src="/logo-text.png" alt="Logo Icon" />
        </nuxt-link>
        <!--  -->
      </div>
      <div class="lg:flex hidden">
        <UModal :ui="uiModal" v-model="isOpen">
          <UCommandPalette ref="commandPaletteRef" :ui="commandsUI" :groups="groups" :autoselect="false" nullable
            :fuse="{ resultLimit: 6, fuseOptions: { threshold: 0.1 } }" @update:model-value="onSelect">
          </UCommandPalette>
        </UModal>
      </div>
      <div class="col-span-2 lg:col-span-1 flex items-center justify-end gap-2 mr-2 md:pr-6">
        <USelectMenu v-model="selectedLocale" :options="locales">
          <template #leading>
            <UIcon v-if="selectedLocale.icon" :name="selectedLocale.icon" class="w-5 h-5" />
          </template>
        </USelectMenu>
        <UButton aria-label="Search" icon="i-ph-magnifying-glass" size="xl" color="white" square variant="solid"
          @click="isOpen = true" />
        <UButton aria-label="Menu" class="lg:hidden" icon="i-ph-list-bold" size="xl" color="white" square
          variant="solid" @click="bus.emit(true)" />
      </div>
    </div>
  </div>
  <!-- <div class="grid gap-y-4 grid-cols-1 xl:grid-cols-2 my-4 w-full">
    <NuxtImg
      format="webm"
      class="w-full xl:pl-8 xl:pr-4 max-h-32"
      src="add.gif"
    ></NuxtImg>
    <NuxtImg
      format="webm"
      class="w-full xl:pr-8 xl:pl-4 max-h-32"
      src="add.gif"
    ></NuxtImg>
  </div> -->
</template>
