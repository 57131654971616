
export const linksUICMP = {
    wrapper: 'space-y-3 mb-4 lg:mb-4 mt-4 -mx-1 lg:mx-0',
    base: 'flex items-center gap-1.5 lg:gap-2 group',
    active: 'text-white font-semibold',
    inactive: 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-200 font-medium',
    icon: {
        wrapper: 'rounded-md p-1.5 inline-flex ring-inset ring-1',
        base: 'w-6 h-6 flex-shrink-0',
        active: 'bg-primary ring-primary text-background',
        inactive: 'bg-gray-100/50 dark:bg-gray-800/50 ring-gray-300 dark:ring-gray-700 group-hover:bg-primary group-hover:ring-primary group-hover:text-background'
    },
    externalIcon: {
        name: 'i-heroicons-arrow-up-right-20-solid',
        base: 'w-3 h-3 absolute top-0.5 -right-3.5 text-gray-400 dark:text-gray-500'
    },
    label: 'text-md/6 relative'
};

export const footerUICMP = {
    wrapper: 'relative',
    bottom: {
        wrapper: '',
        container: 'mx-auto px-2 sm:px-2 lg:px-2 gap-2 max-w-7xl py-8 lg:py-4 lg:flex lg:flex-col lg:items-center items-center lg:justify-between justify-center lg:gap-x-3 flex flex-col-reverse',
        left: 'flex items-center justify-center gap-x-1.5 mt-1',
        center: 'mt-1 flex items-center justify-center gap-1',
        right: 'flex items-center justify-center gap-x-1.5'
    }
};
